<template>
  <div>      
    <CRow v-if="!viewFieldMode">
      <CCol lg="12">
        <transition name="fade">
          <CCard>
            <CCardHeader>
              <CIcon name="cil-chart-line" /> Transações
              <div class="card-header-actions">
                <CLink class="card-header-action btn-minimize" @click="formCollapsed=!formCollapsed">
                  <CIcon :name="`cil-chevron-${formCollapsed ? 'bottom' : 'top'}`"/>
                </CLink>
                <CLink href="#" class="card-header-action btn-close" @click="Fechar">
                  <CIcon name="cil-x-circle"/>
                </CLink>
              </div>
            </CCardHeader>
            <CCollapse :show="formCollapsed">
              <CCardBody>
                <CRow class="align-items-center">
                  <CCol  sm="3" md="2" class="mb-3 mb-xl-0">
                    <CButton block color="dark" shape="pill" @click="newDocument">Novo</CButton>
                  </CCol>
                </CRow>
                <CRow class="align-items-center mt-3">
                  <CCol  sm="12" class="mb-3 mb-xl-0">
                    <CDataTable
                        :hover="hover"
                        :striped="striped"
                        :border="border"
                        :small="small"
                        :fixed="fixed"
                        :items="items"
                        :fields="fields"
                        :items-per-page="small ? 10 : 5"
                        :dark="dark"
                        :noItemsView="noItemsView"
                        pagination
                    >
                        <template #active="{item}">
                            <td>
                                <CBadge v-if="item.active===true" :color="getBadge(item.active)">Ativo</CBadge>
                                <CBadge v-else-if="item.active===false" :color="getBadge(item.active)">Inativo</CBadge>
                            </td>
                        </template>
                        <template #actions="{item}">
                            <td>                               
                                <CButton
                                  :name=item.id
                                  size="sm"
                                  :key="'op'+item.id"
                                  color="success"
                                >
                                  <CIcon size="sm" name="cil-file"/>
                                </CButton>
                                &nbsp;
                                <CButton
                                  :name=item.id
                                  size="sm"
                                  :key="'ed'+item.id"
                                  color="info"
                                  @click="editCompany(item.cnpj)"
                                >
                                  <CIcon size="sm" name="cil-pencil"/>
                                </CButton>
                                &nbsp;
                                <CButton
                                  :name=item.id
                                  size="sm"
                                  :key="'ex'+item.id"
                                  color="danger"
                                  v-if="item.active===true"
                                  @click="deleteCompany(item.cnpj)"
                                >
                                  <CIcon size="sm" name="cil-trash"/>
                                </CButton>
                            </td>
                        </template>
                        <template #fantasy_name="{item}" v-if="isFullDesktop">
                            <td v-if="item.fantasy_name.length > 30">
                              {{item.fantasy_name.substring(0,30 )}}
                              <a v-c-tooltip.hover.click="item.fantasy_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.fantasy_name}}
                            </td>
                        </template>
                        <template #fantasy_name="{item}" v-else>
                            <td v-if="item.fantasy_name.length > 15">
                              {{item.fantasy_name.substring(0,15 )}}
                              <a v-c-tooltip.hover.click="item.fantasy_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.fantasy_name}}
                            </td>
                        </template>
                        <template #company_name="{item}" v-if="isFullDesktop">
                            <td v-if="item.company_name.length > 35">
                              {{item.company_name.substring(0,35)}}
                              <a v-c-tooltip.hover.click="item.company_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.company_name}}
                            </td>
                        </template>
                        <template #company_name="{item}" v-else>
                            <td v-if="item.company_name.length > 20">
                              {{item.company_name.substring(0,20)}}
                              <a v-c-tooltip.hover.click="item.company_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.company_name}}
                            </td>
                        </template>
                    </CDataTable>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCollapse>  
           
          </CCard>
        </transition>
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol lg="12">        
        <CCard>
          <CCardHeader>
            <CIcon name="cil-chart-line" /> Transações
            <div class="card-header-actions">
              <CLink href="#" class="card-header-action btn-close" @click="viewFieldMode=false">
                <CIcon name="cil-x-circle"/>
              </CLink>
            </div>
          </CCardHeader>          
            <CCardBody>
              <CRow class="align-items-center">
                <CCol  sm="3" md="2" class="mb-3 mb-xl-0">
                  <CButton block color="dark" shape="pill" @click="viewFieldMode = false">Desistir</CButton>
                </CCol>
              </CRow>
              <CRow class="align-items-center mt-3">
                <CCol  sm="12" class="mb-3 mb-xl-0">
                 
                </CCol>
              </CRow>
            </CCardBody>          
        </CCard>       
      </CCol>
    </CRow>

       
    <CModal title="Aviso!" color="danger" :show.sync="showAlert">
      {{ msg }}
      <template #footer>        
        <CButton @click="showAlert = false" color="danger">OK</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import functions from "@/functions";
const axios = require("axios").default;

//import "regenerator-runtime";


export default {
  name: 'Transacoes',
  components:{},
  data () {
    return {
      viewFieldMode: false,
      formCollapsed: true,
      hover: false,
      border: false,
      small: false,
      striped: false,
      fixed: false,
      dark: false,
      items:[],
      noItemsView:{ 
          noResults: 'Nenhum resultado para este filtro', 
          noItems: 'Nenhuma empresa cadastrada' 
      },
      isMobile: false,
      isFullDesktop: false,
      fields: [
        {key: 'company_name', label: 'Razão Social'}, 
        {key: 'fantasy_name', label: 'Fantasia'}, 
        {key: 'cnpj'        , label: 'CNPJ'},
        {key: 'active'      , label: 'Ativo'}, 
        {key: 'actions'     , label: 'Ações'}
      ],
      msg: '',
      showAlert: false,
    }
  },
  created: function () { 
    this.getData();
  },
  methods: {
    ...functions,
    getData(){
      return
    },
    Fechar(){
      return
    },
    newDocument(){
      return
    },
    onResize () {
        this.isMobile = window.innerWidth < 576;
        this.isFullDesktop = window.innerWidth > 1300;
    },
  },
  mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
  },  
}
</script>
