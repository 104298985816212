var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.viewFieldMode)?_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('transition',{attrs:{"name":"fade"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-chart-line"}}),_vm._v(" Transações "),_c('div',{staticClass:"card-header-actions"},[_c('CLink',{staticClass:"card-header-action btn-minimize",on:{"click":function($event){_vm.formCollapsed=!_vm.formCollapsed}}},[_c('CIcon',{attrs:{"name":("cil-chevron-" + (_vm.formCollapsed ? 'bottom' : 'top'))}})],1),_c('CLink',{staticClass:"card-header-action btn-close",attrs:{"href":"#"},on:{"click":_vm.Fechar}},[_c('CIcon',{attrs:{"name":"cil-x-circle"}})],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.formCollapsed}},[_c('CCardBody',[_c('CRow',{staticClass:"align-items-center"},[_c('CCol',{staticClass:"mb-3 mb-xl-0",attrs:{"sm":"3","md":"2"}},[_c('CButton',{attrs:{"block":"","color":"dark","shape":"pill"},on:{"click":_vm.newDocument}},[_vm._v("Novo")])],1)],1),_c('CRow',{staticClass:"align-items-center mt-3"},[_c('CCol',{staticClass:"mb-3 mb-xl-0",attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.items,"fields":_vm.fields,"items-per-page":_vm.small ? 10 : 5,"dark":_vm.dark,"noItemsView":_vm.noItemsView,"pagination":""},scopedSlots:_vm._u([{key:"active",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.active===true)?_c('CBadge',{attrs:{"color":_vm.getBadge(item.active)}},[_vm._v("Ativo")]):(item.active===false)?_c('CBadge',{attrs:{"color":_vm.getBadge(item.active)}},[_vm._v("Inativo")]):_vm._e()],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{key:'op'+item.id,attrs:{"name":item.id,"size":"sm","color":"success"}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-file"}})],1),_vm._v("   "),_c('CButton',{key:'ed'+item.id,attrs:{"name":item.id,"size":"sm","color":"info"},on:{"click":function($event){return _vm.editCompany(item.cnpj)}}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-pencil"}})],1),_vm._v("   "),(item.active===true)?_c('CButton',{key:'ex'+item.id,attrs:{"name":item.id,"size":"sm","color":"danger"},on:{"click":function($event){return _vm.deleteCompany(item.cnpj)}}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-trash"}})],1):_vm._e()],1)]}},(_vm.isFullDesktop)?{key:"fantasy_name",fn:function(ref){
var item = ref.item;
return [(item.fantasy_name.length > 30)?_c('td',[_vm._v(" "+_vm._s(item.fantasy_name.substring(0,30 ))+" "),_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover.click",value:(item.fantasy_name),expression:"item.fantasy_name",modifiers:{"hover":true,"click":true}}]},[_vm._v(" ... ")])]):_c('td',[_vm._v(" "+_vm._s(item.fantasy_name)+" ")])]}}:{key:"fantasy_name",fn:function(ref){
var item = ref.item;
return [(item.fantasy_name.length > 15)?_c('td',[_vm._v(" "+_vm._s(item.fantasy_name.substring(0,15 ))+" "),_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover.click",value:(item.fantasy_name),expression:"item.fantasy_name",modifiers:{"hover":true,"click":true}}]},[_vm._v(" ... ")])]):_c('td',[_vm._v(" "+_vm._s(item.fantasy_name)+" ")])]}},(_vm.isFullDesktop)?{key:"company_name",fn:function(ref){
var item = ref.item;
return [(item.company_name.length > 35)?_c('td',[_vm._v(" "+_vm._s(item.company_name.substring(0,35))+" "),_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover.click",value:(item.company_name),expression:"item.company_name",modifiers:{"hover":true,"click":true}}]},[_vm._v(" ... ")])]):_c('td',[_vm._v(" "+_vm._s(item.company_name)+" ")])]}}:{key:"company_name",fn:function(ref){
var item = ref.item;
return [(item.company_name.length > 20)?_c('td',[_vm._v(" "+_vm._s(item.company_name.substring(0,20))+" "),_c('a',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover.click",value:(item.company_name),expression:"item.company_name",modifiers:{"hover":true,"click":true}}]},[_vm._v(" ... ")])]):_c('td',[_vm._v(" "+_vm._s(item.company_name)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1):_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-chart-line"}}),_vm._v(" Transações "),_c('div',{staticClass:"card-header-actions"},[_c('CLink',{staticClass:"card-header-action btn-close",attrs:{"href":"#"},on:{"click":function($event){_vm.viewFieldMode=false}}},[_c('CIcon',{attrs:{"name":"cil-x-circle"}})],1)],1)],1),_c('CCardBody',[_c('CRow',{staticClass:"align-items-center"},[_c('CCol',{staticClass:"mb-3 mb-xl-0",attrs:{"sm":"3","md":"2"}},[_c('CButton',{attrs:{"block":"","color":"dark","shape":"pill"},on:{"click":function($event){_vm.viewFieldMode = false}}},[_vm._v("Desistir")])],1)],1),_c('CRow',{staticClass:"align-items-center mt-3"},[_c('CCol',{staticClass:"mb-3 mb-xl-0",attrs:{"sm":"12"}})],1)],1)],1)],1)],1),_c('CModal',{attrs:{"title":"Aviso!","color":"danger","show":_vm.showAlert},on:{"update:show":function($event){_vm.showAlert=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.showAlert = false}}},[_vm._v("OK")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.msg)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }